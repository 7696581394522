@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.header {
  zoom: 0.8;
  z-index: 10;
  position: relative;
  transition: all 0.25s;
  &.fixedToTop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: $transparent-colour-2;
    transition: all 0.25s;
    @include tablet {
      backdrop-filter: blur(8px);
    }
  }
  &.active {
    backdrop-filter: none;
  }

  &__container {
    @include screenWidth;
  }
  &__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mobile-only {
      padding: 1rem 0.5rem 0.5rem 0.5rem;
    }
  }
  &__secondary-menu {
    padding-left: 0.25rem;
    @include tablet {
      display: none;
      height: fit-content;
      bottom: 0 !important;
      &.active {
        z-index: 5;
        display: flex;
        flex-direction: column;
        align-items: center;
        list-style: none;
        padding: unset;
        margin: 0;
        width: 225%;
        position: absolute;
        @include fadeAndMove(0.5s, 50%, 45%, 50%, 50%);
        bottom: 0;
        left: -175%;
        box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
        opacity: 0;
      }
    }
  }

  &__menu {
    padding-left: 0;
    margin: 0;
    display: flex;
    width: 30%;
    justify-content: space-between;
    align-items: center;
    @include mobile-only {
      display: block;
      z-index: 5;
      position: fixed;
      top: -150%;
      left: 0;
      background-color: $transparent-colour-2;
      backdrop-filter: blur(16px);
      width: 100%;
      transition: all 0.25s ease-in-out;
      box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
      height: calc(100% - 3rem);
      padding-top: 3rem;
      &.active {
        top: 0;
        left: 0;
        @include fadeTop;
        .header__item--film:nth-child(n) {
          @include fadeRight;
        }
        transition: background-color 0.5s;
      }
    }
  }
  &__item {
    list-style-type: none;
    margin: 0;
    @include mobile-only {
      margin: 1rem 0 0 1rem;
      &--film {
        margin: 0.7rem 2.5rem;
      }
    }

    &--alt {
      position: relative;
    }
  }
  &__link {
    width: fit-content;
    text-align: center;
    text-decoration: none;
    font-size: $size-sub;
    padding: 0;
    font-weight: 400;
    color: $black;

    transition: color 0.15s;
    cursor: pointer;
    @include mobile-only {
      color: $black;
      font-weight: 500;
      font-size: 1.1rem;
    }
    &--contact,
    &--about {
      @include underline(-0.5rem);
    }
    &:hover,
    &.active {
      color: $hover-colour;
      @include mobile-only {
        color: $hover-colour;
      }
    }
    &--alt.active,
    &--contact.active,
    &--about.active {
      @include line(-0.5rem);
    }
    &--alt {
      width: fit-content;
      text-decoration: none;
      font-size: $size-sub;
      font-weight: 400;
      color: $black;
      transition: color 0.5s;
      display: flex;
      flex-direction: row;
      position: relative;
      @include mobile-only {
        font-weight: 500;
        font-size: 1.1rem;
      }
      @include tablet {
        margin: 1.5rem 2rem;
      }
    }
  }
  &__left {
    text-decoration: none;
  }
  &__logo {
    font-size: 3rem;

    color: $hover-colour;

    transition: all 0.25s;
    @include tablet {
      padding: 0.5rem 0 0 0;
    }
    @include mobile-only {
      font-size: 8.5vw;
      margin: 0;
      .header.active & {
        text-shadow: none;
        color: $hover-colour;
      }
    }
    @include tablet-only {
      font-size: 5vw;
    }
    font-weight: 500;
    letter-spacing: 1.75px;
    margin: 0 0 0.5rem 0;
    width: auto;
    position: relative;
    z-index: 6;

    &:hover {
      text-shadow: none;
      color: $button-colour;
    }
  }
  &__p {
    margin: 0.5rem 0.5rem;
    text-align: center;
    @include mobile-only {
      text-align: left;
      margin: 0rem 0.5rem 0 0;
    }
  }
  &__link--film {
    font-size: calc(1rem + 1%);
    &:hover,
    &.active {
      font-weight: 500;
    }
    @include mobile-only {
      position: relative;
      font-size: 1rem;
    }
  }
  &__inner {
    @include mobile-only {
      margin-top: 0rem;
    }
    @include tablet {
      position: absolute;
      height: fit-content;
      background: $white;

      top: 0;
    }
  }
}

.header__arrow-open-close {
  position: relative;
  width: 15px;
  height: 20px;

  &:before,
  &:after {
    transition: 0.15s all ease-in-out;
    position: absolute;
    content: "";
    width: 60%;
    height: 2.5px;
    background: $hover-colour;
    top: 50%;
    border-radius: 5px;
  }

  &:before {
    transform: rotate(150deg);
    left: 0;
  }
  &:after {
    transform: rotate(-150deg);
    right: 0;
  }
}

.header__arrow {
  @include mobile-only {
    display: none;
  }
  position: absolute;

  right: -2rem;
  top: 0.25rem;
  width: 50%;
  max-width: 80px;

  .header__arrow-open-close {
    float: left;
  }

  &.hide-toggle {
    .header__arrow-open-close {
      &:before {
        transform: rotate(210deg);
        left: 0;
      }
      &:after {
        transform: rotate(-210deg);
        right: 0;
      }
    }
  }
}
