@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.field {
  margin: 1rem 0;
  position: relative;

  &--error {
    &::before {
      content: "*";
      color: red;
      font-size: 1.75rem;
      position: absolute;
      top: 0.5rem;
      left: -1rem;
    }
  }
  &__label {
    display: none;
  }
  &__input {
    background-color: $white;
    color: $black;
    border: none;
    border-bottom: 2px solid $hover-colour;
    padding: 0.5rem 0;
    font-size: 1.25rem;
    position: relative;
    font-family: "Lora", "Helvetica Neue", Georgia, sans-serif;
    @include mobile-only {
      font-size: 1.1rem;
      width: 45%;
    }
    &::placeholder {
      font-size: 1.25rem;
      color: $black;
      @include mobile-only {
        font-size: 1.1rem;
      }
    }
    &:active,
    &:focus {
      outline: none;
    }
    &--textarea {
      width: 90%;

      min-height: 0rem;
      height: fit-content;
      font-family: "Lora", "Helvetica Neue", Georgia, sans-serif;
      color: $black;
      resize: none;
      position: relative;
      &:active {
        outline: none;
      }
    }
    &--error {
      border-bottom: 2px solid red;
      &::placeholder {
        color: red;
      }
    }
  }
  input[type="text"],
  input[type="email"],
  textarea {
    appearance: none;
    border-radius: 0;
    -webkit-appearance: none;
  }
}
