@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.footer {
  z-index: 0;

  color: $white;

  width: 100%;

  @include desktop {
    margin-top: 0.75rem;
  }
  height: 2rem;
  &__container {
    @include screenWidth;
  }

  &__list {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    width: fit-content;
    @include tablet {
      width: 30%;
    }
    margin: auto;
    padding: 0.5rem 1rem;
    border-radius: 50px;
  }
  &__list-item {
    list-style-type: none;
  }

  &__icon {
    height: 1.1rem;
    width: auto;
    filter: invert(1);

    &:hover {
      @include filterWhiteToColour;
    }
  }
  &__branding {
    text-align: center;
    padding: 0 0.5rem 0.5rem 0.5rem;
    margin: auto;
    font-size: 0.75rem;
    width: fit-content;
    color: $black;
  }
}
