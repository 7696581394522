@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.contact {
  zoom: 0.8;
  min-height: calc(100vh - 1rem);
  display: flex;
  align-items: center;
  justify-content: center;
  @include mobile-only {
    &__wrapper {
      position: absolute;
      width: 100%;
      top: -1rem;
      left: 0;
      @include dottedBackground(4, 50);

      @include fade(0.25s);
    }
  }
  &__undercolour {
    @include tablet {
      position: fixed;
      z-index: -1;
      @include dottedBackground(4, 50);

      bottom: 0;
      left: 0;
      height: 100%;
      width: 50.25%;
      @include fadeRight;
    }
  }
  &__container {
    margin-top: 0rem;
    display: flex;
    gap: 2.5rem;
    @include mobile-only {
      margin-top: 1rem;
      flex-direction: column;
      gap: 0;
    }
    @include tablet {
      margin-bottom: 5rem;
    }
  }
  &__text,
  &__form-wrapper {
    @include tablet {
      width: 100%;
    }
  }
  &__text {
    padding-top: 0rem;
    text-align: right;
    @include fadeLeft;
    @include mobile-only {
      padding: 5rem 2rem 0 0;
    }
  }
  &__title {
    font-size: 5.5rem;
    margin: 0;
    color: $hover-colour;
    @include mobile-only {
      font-size: 12.5vw;
      background-color: $white;
      width: fit-content;
      margin-left: auto;
    }
  }
  &__subtitle {
    border: 2px solid $hover-colour;
    background-color: $white;
    padding: 0.5rem;
    width: 70%;
    margin-left: auto;
    font-size: 1.1rem;
    color: $white;
    @include mobile-only {
      font-size: 1rem;
    }
  }
  &__link {
    text-decoration: none;
    color: $hover-colour;
    @include underline(-0.2rem);
  }
  &__form-wrapper {
    padding-top: 2rem;
    padding-left: 0.25%;
    @include fadeRight;
    @include mobile-only {
      background-color: $white;
      padding-left: 2rem;
      padding-top: 0;
    }
  }
  &__representation,
  &__email {
    font-weight: 500;
    color: $black;
  }
  &__button {
    font-weight: 500;

    background-color: $hover-colour;
    transition: color 0.5s;
    cursor: pointer;
    color: $white;
    border: 3px solid $hover-colour;
    padding: 0.25rem 1rem;
    font-family: "Lora", "Helvetica Neue", Georgia, sans-serif;
    margin: 0;
    font-size: 1.25rem;
    transition: all 0.5s;
    &:hover {
      color: $hover-colour;
      background-color: $white;
    }
    @include mobile-only {
      font-size: 1.1rem;
    }
  }
  &__error-container {
    display: flex;
    height: fit-content;
    color: red;
    font-weight: 500;
  }
  &__error {
    margin: 0;
  }
  &__text--success {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $black;
  }
  &__title--success {
    @include fadeTop;

    font-size: 3.5rem;
    margin: 0;
    font-weight: 400;

    @include mobile-only {
      margin: 1rem 0rem;
    }
  }
  &__subtitle--success {
    @include fadeBottom;
    font-size: 1.75rem;
    width: fit-content;
    color: $hover-colour;
  }
}
