@use "./styles/variables" as *;
@use "./styles/mixins" as *;

.app {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow-x: clip;
  overscroll-behavior: auto;

  &__main {
    @include screenWidth;
  }

  &__section > &__undercolour {
    position: fixed;
    z-index: -1;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;

    background-size: 25%;
    background-color: $bg-light;
    mask-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.2) 0%,
      transparent 30%,
      transparent 70%,
      rgba(255, 255, 255, 0.2) 100%
    );
    @include tablet {
      mask-image: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.2) 0%,
        transparent 30%,
        transparent 70%,
        rgba(255, 255, 255, 0.2) 100%
      );
    }

    @include fade(0.25s);
  }

  &__section {
    margin-top: 1rem;
  }

  &__section--about {
    @include desktop {
      margin-bottom: 1rem;
    }
  }
}

// fixedscroll animation
.header.fixedToTop + .app__main,
.header.fixedToTop + .app__about {
  @include mobile-only {
    margin-top: 4.2rem;
  }
  margin-top: 3.5rem;
}
.header.fixedToTop + .app__main > .app__section {
  margin-top: 4.5rem;

  @include mobile-only {
    margin-top: 5.2rem;
  }
}

.loading {
  width: 100vw;
  height: 100vh;
}
