@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 0rem 0 0.5rem;
  height: 100%;

  overflow-wrap: break-word;

  &::-webkit-scrollbar {
    display: none;
  }
  &--film {
    @include mobile-and-tablet {
      background-color: $hover-colour;

      color: $white;
    }
  }
  @include desktop {
    -webkit-text-stroke-width: 1px;
    color: $hover-colour;
    -webkit-text-stroke-color: $hover-colour;
  }
  &__title {
    margin: 0;

    text-align: left;
    @include mobile-and-tablet {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    &--user {
      text-align: right;
    }
    // END EDIT

    // background-color: $white;
    @include mobile-and-tablet {
      text-align: left;
      // padding-left: 0.25rem;
    }
    @include mobile-only {
      font-size: 9.25vw;
    }
    @include tablet {
      font-size: 8.5vw;
    }
    @include desktop {
      font-size: 4.6vw;
    }
    @include desktopLarge {
      font-size: 5.5rem;
    }
    &--short {
      @include mobile-only {
        font-size: 10.75vw;
      }
      @include tablet {
        font-size: 4.5rem;
      }
      @include desktop {
        font-size: 5rem;
      }
      @include desktopLarge {
        font-size: 6rem;
      }
    }
    &--long {
      @include mobile-only {
        font-size: 10.9vw;
      }
      @include tablet {
        font-size: 6.5vw;
      }
      @include desktop {
        font-size: 5.7vw;
      }
      @include desktopLarge {
        font-size: 4.5rem;
      }
    }
    &--extra-long {
      @include mobile-only {
        font-size: 8.15vw;
      }
      @include tablet {
        font-size: 7.4vw;
      }
      @include desktop {
        font-size: 5.3vw;
      }
      @include desktopLarge {
        font-size: 4.5rem;
      }
    }
    &--extra-long--2 {
      display: block;
      @include mobile-only {
        font-size: 8vw;
      }
      @include tablet {
        font-size: 6.9vw;
      }
      @include desktop {
        font-size: 4.4vw;
      }
      @include desktopLarge {
        font-size: 4.5rem;
      }
    }
  }
  &--user {
    @include mobile-and-tablet {
      justify-content: center;

      .title__longword {
        font-size: 70%;
      }

      color: $white;
      background-color: $hover-colour;
      border-bottom: 6px solid $hover-colour;
      // padding: 0 0rem 0 0.75rem;
    }
    @include desktop {
      justify-content: flex-end;
    }
  }
  &__title--user {
    @include mobile-only {
      font-size: 10.8vw;
    }
    @include tablet-only {
      font-size: 10vw;
    }
  }
  &__firstWord--user {
    display: block;
    font-size: 99%;
    // + span + span {
    //   font-size: 90%;
    // }
  }
  &__longword {
    @include mobile-and-tablet {
      display: flex;
    }
  }

  &__Greatest {
    @include desktop {
      font-size: 4.6vw;
    }
  }
  &__in {
    @include desktop {
      font-size: 5.5vw;
    }
  }

  &__Lee {
    @include mobile-only {
      font-size: 9vw;
    }
    @include desktop {
      font-size: 4.4vw;
    }
  }

  &__is {
    display: block;
    @include mobile-only {
      font-size: 11.5vw;
    }
    @include desktop {
      font-size: 6vw;
    }
  }
  &__Real,
  &__A,
  &__in {
    @include mobile-only {
      font-size: 205%;
      & .title__longword,
      & .title__firstWord--film {
        font-size: 97%;
        display: flex;
      }
      & .title__firstWord--film {
        display: block;
      }
    }
  }
  &__Real {
    @include desktop {
      font-size: 6.7vw;
    }
  }
  &__undefined {
    @include desktop {
      font-size: 9.25vw;
    }
  }
  &__Not {
    display: block;
    @include mobile-only {
      font-size: 9vw;
    }
    @include desktop {
      font-size: 6.25vw;
    }
  }
  &__Antony {
    @include mobile-only {
      font-size: 9vw;
    }
  }
}
