@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.details {
  color: $black;
  background-color: $white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.75rem 1rem;
  &--film {
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    @include desktop {
      background-color: $white;
      border: 3px solid $hover-colour;

      color: $black;

      overflow: hidden;
    }
  }
  &__link {
    text-decoration: none;
    color: unset;
    &:hover {
      transform: scale(1.1);
      transition: transform 0.5s;
      color: $button-colour;
    }
  }
  &--user {
    padding: 0.75rem;
    font-weight: 500;

    @include mobile-and-tablet {
      font-weight: 400;
    }
  }

  &__top {
    padding-left: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.25rem;
    margin: 0;

    & li {
      text-decoration: none;
      margin: 0;
      color: $hover-colour;
    }
  }
  &__role {
    font-weight: 500;

    @include mobile-only {
      max-width: 67.5%;
    }
    @include tablet {
      max-width: 60%;
      overflow: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }
    @include mobile-only {
      font-size: 0.9rem;
    }
  }
  &__wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 0.5rem;
  }
  &__production {
    text-align: right;
  }
  &__length {
    @include mobile-only {
      display: none;
    }
  }
  &__image {
    max-height: 2rem;
    max-width: calc(5rem + 5%);

    display: block;
    @include filterWhiteToColour;

    @include tablet-only {
      max-width: calc(3rem + 5%);
      max-height: 3rem;
    }
    @include mobile-only {
      max-width: 3.25rem;
    }
  }
  &__bio {
    margin: 0.5rem 0;
    &--user {
      margin: 0;
    }
    @include mobile-only {
      font-size: 3.5vw;
    }
    @include tablet {
      font-size: 3.5vw;
    }
    @include desktop {
      font-size: 1.6vw;
    }
    &--extra-short {
      @include mobile-only {
        font-size: 1rem;
      }
      @include tablet-only {
        font-size: 2.25rem;
      }
      @include desktop {
        font-size: 2.2vw;
      }
      @include desktopLarge {
        font-size: 3rem;
      }
    }
    &--short {
      @include mobile-only {
        font-size: 3vw;
      }
      @include tablet-only {
        font-size: 1.75rem;
      }
      @include desktop {
        font-size: 2vw;
      }
      @include desktopLarge {
        font-size: 2.5rem;
      }
    }
    &--extra-long {
      @include tablet-only {
        font-size: 1rem;
        &--user {
          font-size: 2.5vw;
        }
      }
      @include desktop {
        font-size: 1.45vw;
      }
      @include desktopLarge {
        font-size: 1.75rem;
      }
    }
    &--Woody {
      @include mobile-only {
        font-size: 0.9rem;
      }
    }
    &--Jamali {
      @include mobile-only {
        font-size: 0.8rem;
      }
    }
    &--In {
      @include mobile-only {
        // font-size: 1.1rem;
      }
    }
  }
}
