@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.insta {
  gap: 10px;
  width: 95%;
  height: 90%;
  margin: auto;

  &__link {
    text-decoration: none;
    width: calc(calc(100% - 1.5rem) / 2);

    height: calc(50% - 0.5rem);
    position: relative;
    &--user {
      width: 100%;
      height: 100%;
    }
  }
  &__image-container {
    overflow: hidden;
    position: relative;
    height: 100%;

    display: block;
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    display: block;
    position: absolute;
  }
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;

    width: 100%;
    height: 100.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.5s ease, color 0.5s ease;
    color: transparent;
    &:hover {
      z-index: 2;
      background-color: $transparent-colour;
      color: $white;
    }
  }
  &__title {
    font-size: 0.9rem;

    text-align: center;
    display: block;
    &--alt {
      font-size: 1.3rem;
    }
    &--user {
      @include desktop {
        font-size: 1.5vw;
      }
    }
  }
  &__header {
    @include desktop {
      display: none;
    }
    &--alt {
      display: block;
      font-size: 1.5em;
      top: -1.75rem;
    }
  }
}
