@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.description {
  color: $black;
  text-align: center;
  line-height: 1.5rem;

  font-size: 0.9rem;
  @include tablet {
    font-size: calc(1rem + 5%);
  }

  &__title {
    @include carouselTitle;
    &--film {
      @include desktop {
        color: $white;
        font-weight: 500;
      }
    }
  }
  &__content--about {
    background-color: none;
  }
  &--film {
    @include desktop {
      color: $white;
      font-weight: 500;
    }
  }
  &--long {
    margin-top: 1.5rem;
    font-size: 1rem;
    @include mobile-only {
      font-size: 0.9rem;
    }
    @include mobile-and-tablet {
      font-weight: 500;
    }
    @include tablet {
      font-size: calc(0.9rem + 2.5%);
    }
    &--user {
      @include desktop {
        font-size: calc(1.4vw + 1%);
        line-height: calc(1.75vw + 1%);
      }
    }
  }

  &--about {
    font-size: 0.9rem;

    margin: 0;
    padding-top: 1.5rem;
  }
  &__title--about {
    width: calc(50% - 1rem);
    top: -1.5rem;
  }
}
