@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.grid {
  @include desktop {
    zoom: 0.9;
  }
  @include desktop {
    width: 100%;
    height: 100%;
    margin: auto;
    &--film {
      height: unset;
    }
    &--user {
      display: none;
      .about__grid.inView & {
        @include desktop {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          width: 100%;
          height: 100%;
          margin: 0;
          position: relative;
          top: 0;
          left: 0;
        }
      }
    }
  }
  &__description {
    width: 100%;
    background-color: $hover-colour;
  }
  &--alt {
    margin-top: 2rem;

    width: 40%;
  }
  transition: all 0.5s;

  &__undercolour {
    position: fixed;
    z-index: -1;

    height: 50%;
    width: 49.5%;
    background-color: $bg-light;

    @include mobile-only {
      width: 100%;
      height: 15%;
      top: 0;
      display: none;
    }
    &--user {
      top: 0;
      left: 0;
      @include fadeTop;
      animation-delay: 1.25s;
      display: none;
    }
    &--film {
      bottom: 0;
      right: 0;
      @include fadeRight;

      mask-image: linear-gradient(
        to top left,
        rgba(255, 255, 255, 0.2) 0%,
        transparent 80%
      );
    }
    &--two {
      position: fixed;

      @include fadeLeft;

      top: 0;
      left: 0;

      width: 50%;
      height: 50%;
      z-index: -2;
      mask-image: linear-gradient(
        to bottom right,
        rgba(255, 255, 255, 0.2) 0%,
        transparent 80%
      );

      &--user {
        right: 0;
        top: 0;
        left: unset;
        @include fade(0.5s);

        width: 100%;
        height: 100%;
        mask-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.2) 0%,
          transparent 20%,
          transparent 80%,
          rgba(255, 255, 255, 0.2) 100%
        );

        display: none;
      }

      @include mobile-only {
        @include fadeBottom;
        height: 15%;
        width: 100%;
        top: -2%;
      }
    }
  }

  &__list {
    padding-left: 0;
    display: grid;
    grid-template-columns: repeat(
      auto-fit,
      minmax(calc((100% / 10) - 6.5px), 1fr)
    );
    @include desktop {
      grid-template-columns: repeat(
        auto-fit,
        minmax(calc((100% / 10) - 16px), 1fr)
      );
    }
    grid-template-rows: 1fr;
    column-gap: 6.5px;
    @include desktop {
      column-gap: 16px;
    }
    row-gap: 6.5px;
    @include desktop {
      row-gap: 16px;
    }
  }
  &__list--about {
    grid-template-rows: repeat(
      auto-fit,
      minmax(calc((100% / 10) - 6.5px), 1fr)
    );
    @include desktop {
      grid-template-rows: repeat(
        auto-fit,
        minmax(calc((100% / 10) - 16px), 1fr)
      );
    }

    width: 100%;
  }
  &__list--film {
    margin-top: 0.75rem;
  }

  &__item {
    position: relative;
    display: flex;

    &:before {
      content: "";
      display: block;
      height: 0;
      width: 0;
      padding-bottom: calc(5 / 16 * 100%);
    }
    &--film:before {
      padding-bottom: calc(9 / 16 * 100%);
    }
    &--details:before {
      @include desktop {
        padding-bottom: 0;
      }
    }
  }
  &--user &__item {
    z-index: 1;
    &:before {
      padding-bottom: calc(12 / 16 * 100%);
    }
    &--about:before {
      padding-bottom: 65%;
      @include tablet-only {
        padding-bottom: calc(9 / 16 * 100%);
      }
    }
  }
  &--film &__item {
    @include fadeTop;

    &--carousel {
      z-index: 1;
      &:before {
        @include mobile-and-tablet {
          padding-bottom: calc(12 / 16 * 100%);
        }
      }
      @include desktop {
        display: none;
        &.active {
          display: flex;
          @include fadeBottom;
        }
      }
    }
  }

  &__list .grid__item:nth-child(n) {
    @include mobile-and-tablet {
      @include fadeBottom;
    }
  }
  &__list .grid__item:nth-child(1) {
    grid-column: span 10;

    @include desktop {
      grid-column: span 6;

      @include fadeLeft;
      grid-row: span 2;
      order: 1;
    }
  }

  &__list .grid__item:nth-child(2) {
    grid-column: span 10;

    @include mobile-and-tablet {
      order: 4;
      @include fadeTop;
    }
    @include desktop {
      grid-row: span 1;

      animation-delay: 0.25s;
      grid-column: span 10;
      order: 4;
    }
  }
  &__list .grid__item:nth-child(3),
  &__list .grid__item:nth-child(4) {
    @include mobile-and-tablet {
      width: 100%;
      grid-column: span 5;
    }
  }
  &__list .grid__item:nth-child(3) {
    @include mobile-and-tablet {
      @include fadeLeft;
      animation-delay: 0.75s;
      margin-left: auto;
      order: 1;
    }
    @include desktop {
      grid-column: span 4;
      grid-row: span 1;

      @include fadeRight;
      animation-delay: 0.25s;
      order: 2;
    }
  }

  &__list .grid__item:nth-child(4) {
    @include mobile-and-tablet {
      width: calc(100% - 6px);

      border: 3px solid $hover-colour;
    }

    @include fadeRight;
    animation-delay: 1s;

    @include desktop {
      @include fadeBottom;
      animation-delay: 1.25s;

      order: 3;
      grid-column: span 4;

      grid-row: span 1;
      padding-bottom: 0;
    }
  }
  &__list--about .grid__item:nth-child(n) {
    @include desktop {
      grid-column: span 5;
    }
  }
  &__list--about .grid__item:nth-child(1) {
    @include tablet-only {
      &:after {
        padding-bottom: 50%;
      }
    }
    z-index: 1;
    @include desktop {
      @include fadeTop;

      animation-delay: 0.5s;

      grid-row: span 7;
      order: 2;
    }
  }
  &__list--about .grid__item:nth-child(2) {
    @include desktop {
      background-color: $white;
      @include fadeBottom;
      animation-delay: 0.5s;

      order: 3;

      grid-row: span 7;
    }
  }
  // &__list--about .grid__item:nth-child(3),
  // &__list--about .grid__item:nth-child(4) {
  //   // @include mobile-and-tablet {
  //   //   width: calc(100% - 6px);
  //   // }
  // }
  &__list--about .grid__item:nth-child(3) {
    @include fadeLeft;
    animation-delay: 0.75s;
    order: 2;
    // border: 3px solid $hover-colour;

    @include desktop {
      animation-delay: 1s;
      order: 1;

      grid-row: span 3;
    }
  }
  &__list--about .grid__item:nth-child(4) {
    order: 1;
    height: 100%;
    @include desktop {
      box-shadow: none;

      border: none;
      order: 4;

      grid-row: span 3;
      @include fadeRight;
      animation-delay: 1s;
    }
  }
}
