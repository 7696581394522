@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.nomination {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  &__awards {
    @include carouselTitle;

    top: -1.5rem;
    left: 0;
    width: 100%;
    text-align: center;
    &--user {
      @include desktop {
        font-size: 1rem;
        top: -0.5rem;
      }
    }
  }

  &__image-container,
  &__subtext {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
  }
  &__image {
    max-height: 3.75rem;
    max-width: calc(5rem + 5%);
    transition: transform 0.5s;
    display: block;
    @include filterWhiteToColour;
    @include mobile-only {
      max-width: calc(5.5rem);
      max-height: calc(4.5rem);
    }
    @include tablet {
      max-width: calc(6rem + 5%);
      max-height: calc(4.5rem + 5%);
    }
    &:hover {
      transform: scale(1.1);
      filter: none;
    }
    &--user {
      max-height: 4rem;
      max-width: 4rem;
    }
  }

  &__wrapper {
    padding: 1rem 0;
    margin: 0.5rem;
    display: flex;
    flex-direction: column;

    align-items: center;
    text-align: center;

    &--alt {
      width: 100%;
      padding: 0 0.5rem;
    }
  }
  &__subtext {
    margin: 0.5rem 0;
    color: $black;
  }
  &__category {
    font-size: 0.8rem;
    margin: 0;
    font-weight: 500;

    @include tablet {
      font-size: calc(0.9rem + 5%);
    }
  }
  &__result {
    margin: 0.5rem 0;
    text-transform: capitalize;
    font-weight: 300;
    @include mobile-only {
      font-size: 1.25rem;
    }

    color: $hover-colour;
  }
  &__text {
    justify-content: center;
    color: $black;
    max-width: 95%;
    flex: 1;
    font-size: calc($size-text + 1%);
  }
}
