@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.poster {
  width: 100%;

  &__wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    background-color: $white;
  }
  &__image,
  &__video {
    width: inherit;
    height: inherit;
    object-fit: cover;
    object-position: center;
    display: block;
    position: absolute;
  }
  &__overlay {
    @include fullscreenModal;
    transform: translate(0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__button {
    border-radius: 50%;
    background-color: $transparent-colour;
  }
  &__icon {
    border-radius: 50%;
    padding: 0.5rem;
    cursor: pointer;
    filter: invert(1);

    width: 2.5rem;
    height: auto;
    display: block;
    transition: filter 0.5s ease;
    &:hover {
      @include filterWhiteToColour;
      border: 2px solid;
    }
  }
  &__button--alt {
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 2;
    background-color: unset;
  }
  &__icon--alt {
    height: 1.5rem;
    padding: 0rem;
    color: none;
    width: auto;
  }
  &__video {
    position: absolute;
    z-index: 1;
  }
}
