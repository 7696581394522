@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.about {
  @include desktop {
    height: 90vh;

    display: flex;
    justify-content: flex-start;
    transition: all 0.5s both;

    &.active {
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 2rem;
      min-height: 100vh;
      height: 100%;
      .about__top {
        @include fadeBottom;
      }
      .about__hero {
        top: -17.5%;
        height: 130%;

        width: 65%;
      }
      .about__below {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        gap: 2rem;
        @include fadeTop;
        position: relative;

        padding: 5rem 1rem 4rem 1rem;
        overflow: hidden;
      }
      .about__container {
        width: 40%;
        margin-top: 2rem;
      }
      .about__grid {
        display: block;
      }
    }

    position: relative;
  }
  &__top {
    display: flex;
    width: 100%;
  }
  &__below {
    display: none;
  }
  &__grid {
    display: none;
    width: 40%;
  }
  &__container {
    display: none;
    @include desktop {
      @include fadeLeft;
      flex-direction: column;
      display: flex;
      width: 37.5%;
      height: inherit;
      justify-content: center;
    }
  }
  &__description {
    visibility: hidden;
    display: flex;
    width: 60%;
    &.inView {
      visibility: visible;
      background-color: $transparent-colour-2;
      backdrop-filter: blur(1px);
      @include fadeRight;
    }
  }
  &__buttons {
    margin: 1rem 0;
    display: flex;
    gap: 2rem;
    align-items: center;
  }
  &__wrapper {
    margin: 1rem 0;
  }
  &__bio {
    font-weight: 500;
    color: $black;
    font-size: 1rem;
    margin: 0;
    padding: 0.5rem 0;
  }
  &__carousel--outer {
    @include mobile-and-tablet {
      display: none;
    }
    margin-top: 0;
    overflow: hidden;
    height: 80px;
    width: 100%;
    &--alt {
      @include mobile-and-tablet {
        display: block;
        position: absolute;
        bottom: 0;
      }
    }
  }
  &__carousel {
    -webkit-animation: scroll 15s ease-in-out infinite;
    animation: scroll 15s ease-in-out infinite;
  }

  @keyframes scroll {
    0%,
    5% {
      transform: translateY(80px);
    }

    5%,
    30% {
      transform: translateY(0);
    }

    35%,
    62% {
      transform: translateY(-80px);
    }

    66%,
    95% {
      transform: translateY(-160px);
    }

    100% {
      transform: translateY(-240px);
    }
  }
  &__inner {
    font-size: 70px;
    line-height: 80px;
    font-weight: 800;
    margin: 0;
    color: $hover-colour;
  }

  &__button {
    font-weight: 500;
    font-family: "Lora", "Helvetica Neue", Georgia, sans-serif;
    color: $hover-colour;
    background-color: $white;
    transition: background-color 0.5s;
    border: none;
    text-decoration: none;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    cursor: pointer;

    transition: all 0.25s ease-in;
    border: 2px solid $hover-colour;
    &:hover {
      background-color: $hover-colour;
      color: $white;
    }
    &:active {
      color: $darkest-colour;
      border: $darkest-colour;
      background-color: $white;
    }
    &--alt {
      background-color: $hover-colour;
      color: $white;
    }
  }
  &__image-container {
    display: block;
    width: fit-content;
    margin: 0.5rem 0;
  }
  &__image {
    max-width: 6rem;
    @include filterWhiteToColour;
  }
  &__representation {
    font-weight: 400;

    margin: 0;

    color: $black;
  }
  &__link {
    color: $hover-colour;
    text-decoration: none;
    @include underline(-0.1rem);
    font-weight: 500;
  }
  &__text {
    margin: 0rem 0;
  }
  &__show {
    width: 35rem;
    height: 30rem;
    margin: auto;
    @include fadeRight;
  }
  &__hero {
    @include mobile-and-tablet {
      display: none;
    }
    position: absolute;
    width: 70%;
    height: 111.5%;
    top: -12%;
    right: -10%;
    @include fadeRight;
    mask-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1) 80%,
      transparent 100%
    );
    @include desktopLarge {
      right: -20%;
      width: 80%;
    }
  }
  &__background-container {
    position: relative;
  }
  &__background,
  &__background-container {
    width: 100%;
    height: 100%;
  }
  &__background-container {
    mask-image: linear-gradient(to left, rgb(0, 0, 0) 60%, transparent 100%);
  }

  &__background {
    object-fit: cover;
    object-position: center;
    display: block;

    mask-image: linear-gradient(
      to bottom left,
      rgba(0, 0, 0, 1) 70%,
      transparent 100%
    );
  }
}
