@use "./styles/variables" as *;

body {
  margin: 0;
  font-family: "Lora", "Helvetica Neue", Georgia, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
