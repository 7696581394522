$bg-dark: #f97979;
$white: rgb(255, 255, 255);
$transparent-colour-2: #fffc;
$transparent-colour: rgba(255, 136, 201, 0.506);
$transparent-colour-3: rgba(255, 0, 140, 0.75);
$black: #000;
$hover-colour: rgb(255, 0, 141);
$button-colour: rgb(255, 136, 201);
$bg-light: rgb(252, 219, 237);
$darkest-colour: #5f2e49;

$size-text: 0.8rem;
$size-header: 1.6rem;
$size-sub: 1.25rem;
