@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.film {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh);
}
