@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.carousel {
  width: 100%;
  overflow: hidden;
  backdrop-filter: blur(16px);
  background-color: $transparent-colour-2;

  &--user {
    position: relative;
    z-index: 2;
  }
  &__slide {
    height: 100%;
  }
  &__content {
    padding: 1rem;
    height: calc(100% - 4rem);
    @include mobile-only {
      background-color: $transparent-colour-2;
      backdrop-filter: blur(5px);
      padding-top: 1.5rem;
    }
  }

  &__content--nomination,
  &__content--collaborators,
  &__content--description,
  &__content--insta {
    display: flex;
    align-items: center;
  }
  &__content--collaborators,
  &__content--description {
    flex-direction: column;
    justify-content: center;
    margin: 1rem;
  }

  &__content--review {
    padding: 1rem;
    @include mobile-only {
      padding-top: 1.5rem;
    }
    margin: 1rem;
  }
  &__nomination {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
    height: calc(100% - 10px);
    width: 100%;
  }
  &__content--nomination,
  &__content--insta {
    margin: 1rem;
    height: calc(100% - 4rem);
  }
  &__content--nomination--user,
  &__content--insta {
    @include desktop {
      padding: 0;
      margin: 0;
      height: 100%;
    }
  }

  &__content--review {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    &--first {
      margin-top: 1rem;
    }
  }
}

.slick {
  &-arrow,
  &-dots button {
    @include filterWhiteToColour;
  }
  &-arrow {
    display: none !important;
  }
  &-slider {
    height: 100%;
    display: block !important;
  }

  &-dots {
    bottom: 0.5rem;
    @include tablet {
      bottom: -0.5rem;
    }
    @include desktop {
      bottom: -0.25rem;
    }
    li {
      border-radius: 50%;
      padding: 0;
      margin: 0;
      height: fit-content;
    }
  }
  &-dots li {
    border: none !important;
    margin: 0 0.2rem;
  }
  &-dots button {
    width: 100%;
    margin: auto;
  }

  &-list,
  &-track,
  &-slide.slick-active.slick-current > div:first-child,
  &-slide > div:first-child {
    height: 100% !important;
  }
}

.carousel__content--description,
.carousel__content--review,
.carousel__content--collaborators {
  position: relative;

  &:hover {
    filter: none;
  }
  &::before,
  &::after,
  div::before,
  div::after,
  article::before,
  article::after {
    content: "";
    position: absolute;
    width: calc(25% / 2);
    height: calc(25% / 2);
    background: transparent;
    transition: 1s;
  }

  &::before {
    top: -2px;
    left: -2px;
    border-top: 2px solid $hover-colour;
    border-left: 2px solid $hover-colour;
  }
  &::after {
    top: -2px;
    right: -2px;
    border-top: 2px solid $hover-colour;
    border-right: 2px solid $hover-colour;
  }

  article::before,
  div::before {
    bottom: -2px;
    left: -2px;
    border-bottom: 2px solid $hover-colour;
    border-left: 2px solid $hover-colour;
  }
  article::after,
  div::after {
    bottom: -2px;
    right: -2px;
    border-bottom: 2px solid $hover-colour;
    border-right: 2px solid $hover-colour;
  }

  &:hover::before,
  &:hover::after,
  &:hover article::before,
  &:hover article::after,
  &:hover div::before,
  &:hover div::after {
    width: calc(50% / 2);
    height: calc(50% / 2);
  }
}

.carousel__content--nomination,
.carousel__content--insta {
  position: relative;

  z-index: 0;
  &::before,
  &::after,
  div::before,
  div::after {
    content: "";
    position: absolute;
    width: calc(25% / 2);
    height: calc(25% / 2);
    background: transparent;
    transition: 1s;
  }

  &::before {
    top: -2px;
    left: -2px;
    border-top: 2px solid $hover-colour;
    border-left: 2px solid $hover-colour;
  }
  &::after {
    top: -2px;
    right: -2px;
    border-top: 2px solid $hover-colour;
    border-right: 2px solid $hover-colour;
  }

  .carousel__nomination::before {
    bottom: -2px;
    left: -2px;
    border-bottom: 2px solid $hover-colour;
    border-left: 2px solid $hover-colour;
  }

  .carousel__nomination::after {
    bottom: -2px;
    right: -2px;
    border-bottom: 2px solid $hover-colour;
    border-right: 2px solid $hover-colour;
  }

  &:hover::before,
  &:hover::after,
  &:hover div::before,
  &:hover div::after {
    width: calc(50% / 2);
    height: calc(50% / 2);
  }
}

.grid--alt .carousel--alt .carousel__content {
  border: none;
  &--insta {
    margin: 1rem;
    height: calc(100% - 4rem);
    padding: 1rem 0;
  }
}

.carousel__content--about {
  padding: 1rem;
}
