@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.review {
  &__title {
    @include carouselTitle;
    width: calc(100% - 2rem);
  }
  &__wrapper {
    padding: 0rem 0.5rem;
    @include tablet {
      padding: 1rem;
    }

    margin: 0.25rem 0;

    &--last {
      @include mobile-and-tablet {
        padding: 1rem 1.75rem;
      }
    }
  }
  &__rating,
  &__quotation {
    color: $hover-colour;
    text-align: center;
  }
  &__rating {
    font-size: 2rem;

    &--first {
      height: 2rem;
      @include mobile-and-tablet {
        font-size: 1.5rem;
        height: 1.5rem;
      }
      @include tablet-only {
        font-size: 2rem;
        height: 2.25rem;
        margin: 0.25rem;
      }
    }
    &--second {
      font-size: 1.5rem;
      @include tablet-only {
        font-size: 2rem;
      }
    }
  }
  &__quotation {
    font-size: 3rem;
    font-style: italic;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.5rem;
    font-weight: 400;
    margin-bottom: 0.25rem;
    position: relative;
    &--last {
      line-height: calc(1.5rem + 10%);
    }
    @include mobile-only {
      line-height: 1.75rem;
    }
    @include tablet-only {
      line-height: calc(1.75rem + 10%);
    }
  }
  &__quote {
    font-size: 0.9rem;
    padding: 0.25rem;
    text-align: center;
    color: $black;
    margin: 0.5rem;
    @include tablet-only {
      font-size: calc(1rem + 5%);
    }
    @include desktop {
      margin: 0.25rem 0;
      font-size: calc(0.9rem + 5%);
    }
    &--second {
      font-size: 0.9rem;

      @include mobile-only {
        font-size: calc(0.9rem);
      }
      @include tablet-only {
        font-size: calc(1rem + 2.5%);
      }
    }
    &--last {
      font-weight: 500;
      @include tablet-only {
        margin-bottom: 1.5rem;
      }
    }
  }
  &__image {
    max-height: 2.5rem;
    max-width: 9rem;
    display: block;
    @include filterWhiteToColour;
    &--second {
      max-height: 1.5rem;
      width: auto;
    }

    @include mobile-only {
      max-height: 1.5rem;
    }
    @include tablet {
      max-height: 2rem;
    }
  }

  &__image-container,
  &__author {
    display: flex;
    justify-content: center;
    margin: 0;
  }
  &__author {
    font-size: 1rem;
    text-align: center;
    font-weight: 500;
    color: $hover-colour;
    &--second {
      font-size: 1rem;
    }
    @include mobile-only {
      font-size: 1rem;
    }
    @include tablet {
      font-size: calc(1rem + 5%);
    }
  }
}

q::before {
  font-family: Georgia, "Times New Roman", Times, serif;
  content: open-quote;
  position: absolute;
  top: 0.5rem;
  left: -1rem;
}
q::after {
  font-family: Georgia, "Times New Roman", Times, serif;
  content: close-quote;
  position: absolute;
  bottom: -0.5rem;
  right: -1rem;
}
