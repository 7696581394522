@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.hamburger {
  display: none;
  @include mobile-only {
    display: block;
    cursor: pointer;
    z-index: 6;
  }
  &__bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: $hover-colour;
    &:hover {
      background-color: $black;
    }
  }
  &.active {
    .hamburger__bar:nth-child(n) {
      background-color: $black;
    }
    .hamburger__bar:nth-child(2) {
      opacity: 0;
    }

    .hamburger__bar:nth-child(1) {
      transform: translateY(8px) rotate(45deg);
    }

    .hamburger__bar:nth-child(3) {
      transform: translateY(-8px) rotate(-45deg);
    }
  }
}
